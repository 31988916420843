<template>
  <v-container id="formpqr">
    <v-row dense>
      <v-col sm="12" md="12" lg="12" xl="12">
        <v-progress-linear v-if="cargando"
          indeterminate
          color="titlesdark darken-2">
        </v-progress-linear>
        <v-form>
          <v-card v-for="(oForm, i) in aForm" :key="i">
            <Bloques
              :title="oForm.BannerTitle">
            </Bloques>
            <v-container>
              <v-row dense>
                <v-col sm="12" md="6" lg="6" xl="6" v-for="(aData, k) in oForm" :key="k">
                  <component :is="aData.tipocampo"
                    v-model="oFormCampos[aData.idPregunta]"
                    :titulo="aData.Pregunta"
                    :obligatorio="aData.obligatorio == 1 ? true : false"
                    :itemsData="aData.items">
                  </component>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-form>
        <v-card>
          <v-container>
            <v-row justify="center">
              <v-card-actions>
                <v-col sm="12" md="6" lg="6" xl="6">
                  <v-btn
                    v-if="modNuevo" 
                    width="200"
                    color="primary"
                    large
                    @click="saveForm()"
                    :disabled="btnEnable">
                    Guardar
                  </v-btn>
                  <v-btn
                    v-else
                    width="200"
                    color="primary"
                    large
                    @click="updateForm()"
                    :disabled="btnEnable">
                    Actualizar
                  </v-btn>
                </v-col>
                <v-spacer></v-spacer>
                <v-col sm="12" md="6" lg="6" xl="6">
                  <v-btn
                    width="200"
                    @click="CloseDialog()"
                    large>
                    Cancelar
                  </v-btn>
                </v-col>
              </v-card-actions>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import axiosServices from '@/services/axiosServices.js'
import Bloques from '@/components/FormDinamicos/Componentes/Bloque.vue'
import Select from '@/components/FormDinamicos/ComponentesPQR/Select.vue'
import MultiSelect from '@/components/FormDinamicos/ComponentesPQR/Multiselect.vue'
import CheckBox from '@/components/FormDinamicos/ComponentesPQR/CheckBox.vue'
import EditTextNumeros from '@/components/FormDinamicos/ComponentesPQR/TextNumeric.vue'
import EditTextTexto from '@/components/FormDinamicos/ComponentesPQR/Text.vue'
import EditTextAlfanumerico from '@/components/FormDinamicos/ComponentesPQR/TextAlfaNumeric.vue'
import Calendarios from '@/components/FormDinamicos/ComponentesPQR/Calendario.vue'
import RadioButton from '@/components/FormDinamicos/ComponentesPQR/RadioButton.vue'
export default {
  name:"FormDinamicPQR",
  components:{
    Bloques,
    Select,
    MultiSelect,
    CheckBox,
    EditTextNumeros,
    EditTextTexto,
    EditTextAlfanumerico,
    Calendarios,
    RadioButton,
  },
  mounted() {
    this.loadDataEdit()
    this.getNombreCampos()
    this.getFieldForm()
  },
  data() {
    return {
      modNuevo: true,
      cargando: false,
      btnEnable: false,
      aForm: [],
      campos: [],                               // arreglo de campos del formulario  
      oFormCampos: {},
      oIds: {}
    }
  },
  methods: {
    loadDataEdit(){
      this.cargando = true
      axiosServices.getConfiguration(`FormDinamicPQR_item/${this.$route.params.id}`, false, '').then(aItemData =>{
        this.cargando = false
        if(aItemData.aData.length !== 0){
          this.modNuevo = false
          this.oFormCampos = aItemData.aData[0]
          this.oIds = aItemData.aData[1]
        }
      })
    },
    getNombreCampos() {
      this.cargando = true
      axiosServices.getConfiguration(`FormDinamicPQR_Campos/${this.$route.params.id}`, false, '').then(aCampos => {
        this.campos = aCampos.aData
        this.cargando = false
      })
    },
    getFieldForm() {
      this.cargando = true
      axiosServices.getConfiguration(`FormDinamicPQR/${this.$route.params.id}`, false, '').then(getForm => {
        this.aForm = getForm.aData
        this.cargando = false
      })
    },
    async saveForm() {
      this.btnEnable = true  
      this.cargando = true
      if (this.validarCampos()) {
        let aBodySend = []
        for (let [Key, Value] of Object.entries(this.oFormCampos)) {
          let oBody = {
            "idFD_Preguntas": Key,
            "TextoRespuesta": (typeof Value == 'object') ? Value.DatoMostrar : (Value.substring(1, 0) == '{') ? JSON.parse(Value).DatoMostrar : Value,
            "ValorRespuesta": (typeof Value == 'object') ? Value.ID : (Value.substring(1, 0) == '{') ? JSON.parse(Value).ID : '0',
            "TipoPregunta": this.getTipoCampo(Key),
            "Detalle": '',
            "idPQR_Requerimientos": this.$route.params.id,
            "IdUser": this.$route.query.iduser
          }   
          aBodySend.push(oBody)
        }
        let oResponse =  await axiosServices.postConfiguration(`FormDinamicPQR/${this.$route.params.id}`, aBodySend, true, '')
        if (oResponse.sStatus == 'success'){
          this.cargando = true
          this.$swal({
            timer: 1500,
            icon:`${oResponse.sStatus}`,
            title:`${oResponse.sMessage}`,
            showConfirmButton: false
          })
          this.$emit('saveOK')
          this.oFormCampos = {}
          this.CloseDialog()
        }
      } else {
        this.btnEnable = false
        this.cargando = true
      }
    },
    async updateForm() {
      this.btnEnable = true  
      this.cargando = true
      if (this.validarCampos()) {
        let aBodySend = []
        for (let [Key, Value] of Object.entries(this.oFormCampos)) {
          let oBody = {
            "idPQR_Rsp_FormDinamicos": this.oIds[`${Key}`],
            "idFD_Preguntas": Key,
            "TextoRespuesta": (typeof Value == 'object') ? Value.DatoMostrar : (Value.substring(1, 0) == '{') ? JSON.parse(Value).DatoMostrar : Value,
            "ValorRespuesta": (typeof Value == 'object') ? Value.ID : (Value.substring(1, 0) == '{') ? JSON.parse(Value).ID : '0',
            "TipoPregunta": this.getTipoCampo(Key),
            "Detalle": '',
            "idPQR_Requerimientos": this.$route.params.id,
            "IdUser": this.$route.query.iduser
          }   
          aBodySend.push(oBody)
        }
        let oResponse =  await axiosServices.putConfiguration(`FormDinamicPQR/${this.$route.params.id}`, aBodySend, true, '')
        if (oResponse.sStatus == 'success'){
          this.cargando = true
          this.$swal({
            timer: 1500,
            icon:`${oResponse.sStatus}`,
            title:`${oResponse.sMessage}`,
            showConfirmButton: false
          })
          this.$emit('saveOK')
          this.oFormCampos = {}
          this.CloseDialog()
        }
      } else {
        this.btnEnable = false
        this.cargando = true
      }
    },
    validarCampos(){
      // esta funcion valida que los campos obligatorios esten completos y regrese un objeto de los errores true indica que hay error
      // tomo el objeto de elementos y los recorro uno a uno
      let oFormError      = {}
      let aux_nombreCampo = ''
      let bRspVAlidar     = true   // variable empleada para dar la respueta de si se continua con el post o no
      for (let prop in this.campos) {
        aux_nombreCampo = this.campos[prop].idPregunta
        // valido si es obligatorio y si tienen valor
        if (this.oFormCampos[aux_nombreCampo] != undefined) {
          if (this.campos[prop].obligatorio == '1' && this.oFormCampos[aux_nombreCampo] != '') {
            oFormError[aux_nombreCampo] = false
          } else {
            if (this.campos[prop].obligatorio == '0') {
              oFormError[aux_nombreCampo] = false
            } else {
              oFormError[aux_nombreCampo] = true
              bRspVAlidar = false
            }  
          }  
        } else {
          if (this.campos[prop].obligatorio == '0') {
            oFormError[aux_nombreCampo] = false
          } else {
            oFormError[aux_nombreCampo] = true
            bRspVAlidar = false
          }  
        }
      }
      this.$store.dispatch('saveoFormError', oFormError)
      return bRspVAlidar
    },
    getTipoCampo(IdPregunta) {
      let value = ''
      this.campos.forEach(Element => {
        if (Element.idPregunta == IdPregunta) {
          value = Element.tipocampo
        }
      })
      return value
    },
    CloseDialog() {
      window.parent.close()
    }
  },
}
</script>